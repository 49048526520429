.slide__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 660px;
    font-size: 16px;
}

.slide__img {
    width: 360px;
    height: 480px;
    object-fit: cover;

    @media(max-width: 900px) {
        max-width: 280px;
    }
    
    @media(max-width: 700px) {
        max-width: calc(100vw - 90px);
    }
}