.prices-screen__wrapper {
    background-color: silver;
    padding: 8px;

    @media(min-width: 650px) {
        padding: 2rem;
    }
}

.prices-screen__container {
    max-width: 900px;
    margin: 0 auto;
}

.prices-screen__header {
    font-size: 20px;
    font-weight: 500;
    margin: 0 auto;
    transform: translate(23px, 0);
}

.prices-screen__body {
    width: 100%;
    text-align: center;
}

.prices-screen__text-container {
    display: flex;
    width: 80%;
    margin: 20px auto 10px;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    & span {
        flex-shrink: 0;
        text-align: center;
        display: block;
    }
    & hr {
        display: none;
    }
    & span:first-child {
        margin: 0 10px 0 0;
    }
    & span:last-child {
        margin: 0 0 0 0;
    }
    & hr {
        flex-wrap: 0;
        width: 100%;
        margin-bottom: 4px;
    }
    @media(min-width: 650px) {
        width: 50%;
    }

    @media(min-width: 1000px) {
        & hr {
            display: block;
        }
        & span {
            text-align: start;
        }
        flex-direction: row;
        & span:last-child {
            margin: 0 0 0 10px;
        }
    }
}

.MuiAccordion-root.Mui-expanded {
    margin: 2px 0!important;
}

.MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(45deg)!important;
}

.MuiAccordionSummary-expandIcon:hover {
    background-color: WhiteSmoke !important;
}