.why-we-screen__wrapper {
    min-height: 93vh;
    padding: 10px;

    @media(min-width: 650px) {
        padding-top: 3rem;
    }
}

.why-we-screen__container {
    max-width: 1200px;
    margin: 0 auto;
}

.why-we-screen__why {
    text-align: left;
    padding-left: 20px;
    transform: translate(20%, 0);
    font-weight: 500;
    text-align: center;
    font-size: 8vw;
    margin: 10px 0;
    text-transform: uppercase;
}

.why-we-screen__we {
    text-align: right;
    padding-right: 40px;
    transform: translate(-20%, 0);
    font-weight: 500;
    text-align: center;
    font-size: 8vw;
    margin: 10px 0;
    text-transform: uppercase;
}

.why-we-screen__cards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.why-we-screen__card {
    line-height: 24px;
    max-width: 360px;
    margin: 1rem;
}

.why-we-screen__video-container {
    width: 100%;
    padding-top: 56%;
    position: relative;
}

.why-we-screen__header {
    font-weight: 600;
    font-size: 20px;
    margin: 15px 0;
}

.why-we-screen__video {
    width: 100%;
    position: absolute;
    top: 0;
    height: 100%;
}