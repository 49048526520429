.reviews-screen__wrapper {
    background-color: #EFE0CD;
    padding: 1rem;

    @media(min-width: 1000px) {
        padding: 3rem;
    }
}

.reviews-screen__flex-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.reviews-screen__rewiews {
    font-size: 14px;
    text-align: center;

    @media(min-width: 650px) {
        font-size: 18px;
    }
}

.reviews-screen__swiper-button {
    width: 60px;
    height: 60px;
    border-radius: 50%;

    &:hover {
        cursor: pointer;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    
    svg {
        width: 16px;
        height: 29px;
    };

    &-transform {
        transform: rotate(180deg);
    }
}


.reviews-screen__slide {
    padding-top: 2rem;
    width: calc(100vw - 2rem - 140px);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media(min-width: 1000px) {
        width: 666px;
    }
}

.reviews-screen__container {
    width: calc(100vw - 2rem - 140px);

    @media(min-width: 1000px) {
        width: 666px;
    }
}
.reviews-screen__name {
    font-size: 16px;
    font-weight: 600;
}

.reviews-screen__avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin: 20px;
}

.reviews-screen__instagram {
    margin-top: 1rem;
    color: grey;
}
