.footer-screen__wrapper {
    background-color: black;
    padding: 2rem;
}

.footer-screen__container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    color: white;

    @media(max-width: 1000px) and (min-width: 420px) {
        justify-content: center;
    }

}

.footer-screen__block-with-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
}

.footer-screen__icons {
    display: flex;
    margin: 0 0 20px 0;
}

.footer-screen__white-circle {
    color: black;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0px 10px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
}

.footer-screen__logo {
    width: 250px;
    height: 100%;
    object-fit: contain;

    @media(max-width: 650px) {
        display: none;
    }
}

.footer-screen__header {
    font-size: 20px;
    font-weight: 500;
    margin: 5px 0;
}

.footer-screen__link {
    margin: 5px;
    cursor: pointer;
    display: block;
}