.us-instagram-screen__wrapper {
    padding: 2rem;
}

.us-instagram-screen__container {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    font-size: 10vw;
    line-height: 10vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media(min-width: 1000px) {
        font-size: 7vw;
        line-height: 7vw;
    }
}

.us-instagram-screen__us {
    @media(max-width: 1000px) {
        align-self: flex-start;
    }

    @media(min-width: 1000px) {
        transform: translate(-50%, 0);
    }
}

.us-instagram-screen__instagram {
    text-align: right;

    @media(min-width: 1000px) {
        transform: translate(50%, 0);
    }
}

.us-instagram-screen__images-container {
    margin: 2rem auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.us-instagram-screen__image-container {
    margin: 0 auto;
    width: 45%;
    overflow: hidden;

    @media(min-width: 1000px) {
        width: 23%;
    }
}

.us-instagram-screen__image {
    width: 100%;
    object-fit: contain;
    cursor: pointer;
    transition: all 1s ease-out;
    
    &:hover {
        transform: scale(1.1);
    }
}