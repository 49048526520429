.care-screen__wrapper {
    background-color: #fdfdfd;
    padding: 0 20px;
}

.care-screen__container {
    position: relative;
    max-width: 960px;
    margin: 0 auto;

    & h1 {
        color: #bee6f9;

        @media(min-width: 1000px) {
            padding-left: 180px;
        }
    }
}

.care-screen__header {
    font-weight: 600;
    font-size: 20px;
    padding: 20px 0;
    text-align: center;
}

.care-screen__top {
    line-height: 23px;

    & div {
        margin: 0 1rem;
    }

    @media(min-width: 1000px) {
        display: flex;
    }
}

.care-screen__bottom {
    margin: 30px 0;
    line-height: 23px;
    display: flex;

    @media(min-width: 650px) {
        & div {
            max-width: 370px;
            padding: 30px 0 0 100px;
        }
    }

    @media(min-width: 1000px) {
        & div {
            padding: 50px 0 0 200px;
        }
    }
}

.care-screen__image1-container {
    width: 100%;

    @media(min-width: 1000px) {
        width: 75%;
        object-fit: contain;
        margin: -120px 0 0 -230px;
    }
}

.care-screen__image1 {
    width: 100%;

    @media(min-width: 1000px) {
        width: 40vw;
        object-fit: contain;
        margin: -120px 0 0 -130px;
    }
}

.care-screen__image2 {
    height: 200px;
    width: 100%;
    object-fit: contain;

    @media(min-width: 650px) {
        width: 50%;
    }

    @media(min-width: 1000px) {
        height: auto;
    }
}

.care-screen__picture2 {
    display: flex;
    justify-content: center;
}
